<template>
  <div id="navbar">
    <div id="nav-items" class="flex active">
      <img id="header-swan" width="50px" height="50px" src="../img/weisserSchwan.svg" />
      <router-link class="link" to="/">Startseite</router-link>
      <router-link class="link" to="/Ladies">Damen</router-link>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
#nav-items {
  color: gold;
  opacity: 0;
  pointer-events: none;
  transition: right 1s, opacity 1s;
}
#nav-items.active {
  right: 70px;
  pointer-events: all;
  opacity: 1;
}
#nav-items .link {
  @apply pr-4;
  font-size: 25px;
  font-family: "Great Vibes", cursive;
}
#nav-items .link:hover {
  transform: scale(1.05);
  transition: all 1s;
  color: #fff;
}
#header-swan {
  margin-right: 10px;
}
.hidden {
  visibility: hidden;
}
</style>
