<template>
  <div id="header">
    <NavbarView></NavbarView>
  </div>
</template>

<style scoped></style>

<script>
import NavbarView from "./NavbarView.vue";
export default {
  components: {
    NavbarView,
  },
};
</script>
