<template>
  <HeaderView></HeaderView>
  <router-view />
  <router-link class="impressum" to="/Impressum">Impressum / Datenschutzerklärung</router-link>
</template>

<script>
import HeaderView from "./components/HeaderView.vue";
// import LadiesView from "./components/LadiesView.vue";

export default {
  components: {
    HeaderView,
  },
};
</script>

<style>
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
}
.body-content {
  flex: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.body-content::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "Great Vibes";
  src: local("Vibes"), url(./fonts/GreatVibes-Regular.ttf) format("truetype");
}
div.formFooter.f6 {
  display: none !important;
}
.impressum {
  font-size: 25px;
  font-family: "Great Vibes", cursive;
  color: gold;
  text-align: center;
  padding-top: 20px;
}
.impressum:hover {
  transform: scale(1.05);
  transition: all 1s;
  color: #fff;
}
</style>
