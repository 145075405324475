<template>
  <div class="girl-wrapper">
    <div class="availability">{{ girl.zeitraum }}</div>
    <div id="girl" class="body-content">
      <slider-view v-if="girl.name" id="pictures" :girl="girl"></slider-view>

      <div id="setcard">
        <div class="headline">Setcard</div>
        <div class="adress">Lübecker Str. 113 | 01157 Dresden | Klingeln bei Beer im DG | {{ girl.nummer }}</div>
        <div v-if="getHotelVisits(girl.hotelbesuche)">Ich besuche dich auch im Hotel</div>
        <div class="girlfacs">
          <div class="age">Alter:</div>
          <div class="age">{{ girl.alter }}</div>
          <div>Herkunft:</div>
          <div>{{ girl.herkunft }}</div>
          <div>Konversation:</div>
          <div class="language">{{ getGirlLanguage(girl.sprachen) }}</div>
          <div>Größe:</div>
          <div>{{ girl.groesse }}</div>
          <div>Konfektion:</div>
          <div>{{ girl.konfektion }}</div>
          <div>Oberweite:</div>
          <div>{{ girl.oberweite }}</div>
          <div>Haarfarbe:</div>
          <div>{{ girl.haarfarbe }}</div>
          <div>Bikinizone:</div>
          <div>{{ girl.bikinizone }}</div>
          <div>Rauchen:</div>
          <div>{{ girl.rauchen }}</div>
          <div>Tattoos:</div>
          <div>{{ girl.tattoos }}</div>
        </div>
      </div>
      <div id="secret">
        <div class="headline">Secrets</div>
        <div class="secret">
          <div class="secretcontent" v-for="secret in separateSecrets(girl.secrets)" :key="secret">
            <img id="swanemblem" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
            <div class="secretname">{{ secret }}</div>
          </div>
        </div>
      </div>
      <div id="money">
        <div class="headline">Honorar</div>
        <div class="passus">{{ Ladies.passus }}</div>
        <div class="money-section">
          <div class="casualprice">
            <div class="myprices">Meine Preise:</div>
            <div class="my-price-section" v-for="(honorar, index) in getHonorar(girl.honorar)" :key="index">
              <div class="min">{{ getMinutes(index) }}</div>
              min:
              <div class="price">{{ honorar }}€</div>
            </div>
          </div>
          <div class="highprice">
            <div v-if="getAufpreise()" class="myprices">Meine Extras:</div>
            <div v-for="aufpreis in separateAufpreise(girl.aufpreise)" :key="aufpreis">
              {{ aufpreis }}
            </div>
          </div>
        </div>
        <p v-if="girl.gutschein === 'TRUE'">Mit folgendem Gutschein, erhälst du 10€ Rabatt auf deinen Besuch:</p>
        <p v-if="girl.gutschein === 'TRUE'">
          <a target="_blank" href="https://weisser-schwan-dresden.de/gutschein.jpg" download>
            <img src="../../public/gutschein.png" alt="weisser schwan" width="104" height="142" />
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SliderView from "./SliderView.vue";
export default {
  data() {
    return {
      Ladies: {},
      girl: {},
    };
  },

  components: {
    SliderView,
  },

  methods: {
    renderData() {
      let actualLadieName = this.$route.params.name;
      this.Ladies.forEach((ladie) => {
        if (ladie.name === actualLadieName) {
          this.girl = ladie;
        }
      });
    },

    separateSecrets(secrets) {
      if (secrets) {
        return secrets.split(/,/);
      }
    },

    getHonorar(honorar) {
      if (honorar) {
        return honorar.split(/,/);
      }
    },

    getHotelVisits(visits) {
      if (visits === "TRUE" || visits === "true") {
        return true;
      }
      return false;
    },

    separateAufpreise(aufpreise) {
      if (aufpreise) {
        return aufpreise.split(/,/);
      }
    },

    getMinutes(index) {
      switch (index) {
        case 0:
          return "15";
        case 1:
          return "30";
        case 2:
          return "45";
        case 3:
          return "60";
        case 4:
          return "90";
        case 5:
          return "120";
      }
    },

    getGirlLanguage(languages) {
      if (languages) {
        return languages.replace(/,/g, ", ");
      }
    },

    getAufpreise() {
      if (this.girl.aufpreise) {
        return this.girl.aufpreise.length > 0;
      }
    },
  },

  mounted() {
    try {
      fetch("../girls.json", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.Ladies = data;
          this.renderData();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.warn("Waiting for Data");
    }
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.girl-wrapper {
  position: relative;
  overflow: auto;
  width: 100%;
}

#girl {
  position: relative;
  overflow: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(195px, 45vw), 2fr));
  grid-gap: 40px;
}

.availability {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 25px;
  color: rgba(255, 217, 0, 0.8);
}

.headline {
  font-family: "Great Vibes";
  margin: 20px;
  font-size: 30px;
  position: relative;
}

.secret {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 15px;
  width: 100%;
  padding: 30px;
}

.secretname {
  width: 100%;
  text-align: center;
}

.myprices {
  margin-bottom: 10px;
  text-decoration: underline;
}

.casualprice {
  padding-right: 75px;
  text-align: left;
  float: left;
}

.passus {
  margin-left: 50px;
  margin-right: 50px;
  text-align: left;
  float: left;
}

.girlfacs,
.secretcontent {
  display: grid;
  grid-template-columns: 50px auto;
  padding: 2px;
}

.secretcontent {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.girlfacs {
  margin-bottom: 15px;
  column-gap: 150px;
  margin-right: 50px;
  padding: 25px;
  text-align: left;
  float: left;
}

.adress {
  padding-bottom: 10px;
}

.my-price-section {
  display: flex;
}

.price {
  width: 43px;
  text-align: center;
  padding-left: 5px;
}

.min {
  margin-right: 10px;
  width: 25px;
  text-align: center;
}

@media only screen and (max-width: 460px) {
  .adress {
    padding-left: 10px;
    padding-right: 10px;
  }
  .girlfacs {
    padding-left: 75px;
    column-gap: 80px;
  }

  .availability {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    font-size: 20px;
    color: rgba(255, 217, 0, 0.8);
  }
}

@media only screen and (max-width: 460px) {
  .casualprice {
    padding-right: 25px;
  }
}

#setcard,
#secret,
#money {
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(255, 217, 0, 0.8);
  border: 1px solid rgba(255, 217, 0, 0.8);
  text-align: center;
  /* font-family: "Great Vibes"; */
  font-size: 15px;
}

#pictures,
#money,
#secret {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

.money-section {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 20px;
}

.highprice {
  margin-left: 100px;
  text-align: left;
  float: left;
}

.max-picture {
  margin-bottom: 16px;
  border: 1px solid gold;
}

.min-pictures {
  display: flex;
}

#swanemblem {
  height: 50px;
  width: 50px;
}

.min-pictures img {
  margin: 0 8px;
  border: 1px solid gold;
}

@media only screen and (max-width: 1220px) {
  .money-section {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 20px;
    margin-left: 30px;
    margin-bottom: 20px;
  }
  .highprice {
    margin-left: 80px;
    text-align: left;
    float: left;
  }
}

@media only screen and (max-width: 1100px) {
  .highprice {
    margin-left: 20px;
    text-align: left;
    float: left;
  }
}

@media only screen and (max-width: 900px) {
  .highprice {
    margin-left: 2px;
    text-align: left;
    float: left;
  }
}

#setcard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 460px) {
  .money-section {
    grid-template-columns: unset;
    grid-gap: 41px;
  }
  .highprice {
    margin-left: unset;
  }
}
</style>
