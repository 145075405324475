<template>
  <!-- Slideshow container -->
  <div class="slideshow-container">
    <!-- Full-width images with number and caption text -->

    <div v-for="picture in separatePictures(girl.bilder)" :key="picture">
      <div class="mySlides fade">
        <div class="numbertext">{{ getIndex(picture, girl.bilder) }} / {{ getLength(girl.bilder) }}</div>
        <img class="girlpic" :src="getPictureURL(picture)" alt="Girl" />
      </div>
    </div>

    <!-- Next and previous buttons -->
    <a class="prev" @click="plusSlides(-1)">&#10094;</a>
    <a class="next" @click="plusSlides(1)">&#10095;</a>
  </div>
</template>

<script>
export default {
  props: {
    girl: Object,
  },

  data() {
    return {
      slideIndex: 1,
    };
  },

  mounted() {
    this.showSlides();
  },

  methods: {
    // Next/previous controls
    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    // Thumbnail image controls
    currentSlide(n) {
      this.showSlides((this.slideIndex = n));
    },

    separatePictures(bilder) {
      return bilder.split(/,/);
    },

    getLength(bilder) {
      let bilderContainer = bilder.split(/,/);
      return bilderContainer.length;
    },

    getIndex(picture, bilder) {
      let bilderContainer = bilder.split(/,/);
      return bilderContainer.indexOf(picture) + 1;
    },

    getPictureURL(picture) {
      try {
        return "/lady_pictures/" + picture;
      } catch (error) {
        return "";
      }
    },

    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[this.slideIndex - 1].style.display = "block";
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

.mySlides.fade {
  border: 1px solid gold;
  width: auto;
  height: 460px;
}

@media (max-width: 460px) {
  .mySlides.fade {
    height: auto;
    width: auto;
  }
}

.girlpic {
  width: auto;
  height: 100%;
}

@media (max-width: 460px) {
  .girlpic {
    width: auto;
    height: auto;
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: gold;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev {
  left: 23px;
}

.next {
  margin-right: 25px;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  font-family: "Great Vibes";
  color: gold;
  font-size: 20px;
  padding: 8px 12px;
  position: absolute;
  padding-top: 3px;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: gold;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
</style>
