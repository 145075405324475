<template>
  <div id="ladiesview" class="body-content">
    <template v-if="getActualRoute === '/Ladies' && Ladies">
      <ShowRoom :ladies="Ladies"></ShowRoom>
    </template>
  </div>
</template>
<script>
import ShowRoom from "./ShowRoom.vue";
export default {
  data() {
    return {
      Ladies: {},
    };
  },

  components: {
    ShowRoom,
  },

  computed: {
    getActualRoute() {
      return this.$route.path;
    },
  },

  beforeMount() {
    fetch("girls.json", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        this.Ladies = data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
</script>

<style scoped>
#ladiesview {
  position: relative;
  overflow: auto;
  width: 100%;
  margin: 20px 0;
}
</style>
