import ImpressumView from "./components/ImpressumView.vue";
import MainPage from "./components/MainPage.vue";
import LadiesView from "./components/LadiesView.vue";
import GirlView from "./components/GirlView.vue";

export default [
  { path: "/Ladies", component: LadiesView },
  { path: "/Impressum", component: ImpressumView },
  { path: "/Girl/:name", component: GirlView },
  { path: "/", component: MainPage },
];
