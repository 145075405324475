<template>
  <div id="bodyContentStart" class="body-content">
    <img id="swan" src="../img/weisserSchwan.svg" />
  </div>
</template>

<style scoped>
#bodyContentStart {
  margin: auto;
  max-width: 100%;
  height: auto;
}

#swan {
  width: auto;
  height: 100%;
  opacity: 0.2;
}
</style>
