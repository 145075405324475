import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
//import LadiesView from "./components/LadiesView.vue";

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.mount("#app");
