<template>
  <div>
    <div class="between-head">Aktuell im Haus</div>
    <div class="between-head">Woche vom {{ getDate() }}</div>
    <div class="actualGirls">
      <template v-for="ladie in Array.from(ladies)">
        <div v-if="isHere(ladie)" @click="routeToGirl(ladie)" class="quadrat" :key="ladie.name">
          <div class="ladieName">{{ ladie.anzeigename }}</div>
          <img v-if="ladie.bilder" class="ladiespic" :src="getPictureURL(ladie)" alt="Girl" />
          <template v-if="checkEmpfehlung(ladie)">
            <div class="ladiespic-detail">
              <img :src="require(`../img/gold-star.png`)" class="starleft" />
              <div class="reference">Empfehlung des Hauses</div>
              <img :src="require(`../img/gold-star.png`)" class="starright" />
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="between-head second">
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <div class="between sequence">
        <div>Bald wieder</div>
        <div>im Haus</div>
      </div>
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
      <img id="header-swan" width="50px" height="50px" src="../../public/weisserSchwan.svg" />
    </div>
    <div class="actualGirls">
      <template v-for="ladie in Array.from(ladies)">
        <div v-if="!isHere(ladie) && ladie.name" @click="routeToGirl(ladie)" class="quadrat" :key="ladie.name">
          <div class="ladieName">{{ ladie.name }}</div>
          <img v-if="ladie.bilder" class="ladiespic" :src="getPictureURL(ladie)" alt="Girl" width="200" height="500" />
          <template v-if="checkEmpfehlung(ladie)">
            <div class="ladiespic-detail">
              <img :src="require(`../img/gold-star.png`)" class="starleft" />
              <div class="reference">Empfehlung des Hauses</div>
              <img :src="require(`../img/gold-star.png`)" class="starright" />
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import GirlView from "./GirlView.vue";
export default {
  props: {
    ladies: Object,
  },

  methods: {
    routeToGirl(ladie) {
      var name = ladie.name;
      this.$router.push({ path: "/Girl/" + name, component: GirlView });
    },

    getDate() {
      try {
        return Array.from(this.ladies).find((entry) => entry.woche !== undefined).woche;
      } catch (error) {
        return "";
      }
    },

    isHere(ladie) {
      if (ladie.ist_aktuell_da === "true" || ladie.ist_aktuell_da === "TRUE") {
        return true;
      }
      return false;
    },

    checkEmpfehlung(ladie) {
      if (ladie.empfehlung === "true" || ladie.empfehlung === "TRUE") {
        return true;
      }
      return false;
    },

    separatePictures(bilder) {
      return bilder.split(/,/);
    },

    getPictureURL(ladie) {
      var pictures = this.separatePictures(ladie.bilder);
      try {
        return "/lady_pictures/" + pictures[0];
      } catch (error) {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.between-head {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gold;
  font-size: 28px;
  text-align: center;
  margin: 24px 0;
  font-family: "Great Vibes", cursive;
}
.between-head.second {
  margin-top: 100px;
  margin-bottom: 100px;
}
.actualGirls {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-row-gap: 40px;
}

.ladieName {
  font-family: "Great Vibes", cursive;
  color: gold;
  font-size: 22px;
}

.quadrat {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.between-head.second {
  overflow-x: hidden;
  white-space: nowrap;
}

.ladiespic {
  height: 300px;
  width: 220px;
  border: 1px solid gold;
  cursor: pointer;
}

.ladiespic-detail {
  display: flex;
  cursor: pointer;
  font-family: "Great Vibes", cursive;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 220px;
  font-size: 18px;
  color: gold;
  text-align: center;
  border: 1px solid gold;
}
.starleft {
  border: unset;
  height: 24px;
  width: 24px;
  padding-top: 3px;
}
.starright {
  border: unset;
  height: 24px;
  width: 24px;
  padding-top: 3px;
}
.reference {
  flex: 1;
  padding-top: 3px;
}
</style>
